import React from "react"

import { ThemeProvider } from "styled-components"
import { theme } from "./src/config"
import { MockProvider } from "./src/context"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <MockProvider>{element}</MockProvider>
  </ThemeProvider>
)
