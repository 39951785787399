export default {
  text:
    "Site de vente aux enchères des biens d’occasion des mairies, organismes publics et grandes entreprises. Nous facilitons la revente de biens dans une démarche d’économie circulaire et de transparence.",
  // pages: [
  //   { label: "Immobilier", url: "https://www.agorastore.fr/immobilier" },
  //   {
  //     label: "Véhicules et équipements roulant",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  //   {
  //     label: "à propos d'agorastore",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  // ],
  // subPages: [
  //   { label: "Devenir vendeur", url: "https://www.agorastore.fr/immobilier" },
  //   {
  //     label: "FAQ",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  //   {
  //     label: "Emploi",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  //   {
  //     label: "blog",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  //   {
  //     label: "CGU",
  //     url: "https://www.agorastore.fr/immobilier",
  //   },
  // ],
  sponsorLogo: "liste des guides",
  catchlineSponsor: "nous accorde le label",
  labelLogo: "",
  rsx: {
    title: "Nous suivre",
  },
}
