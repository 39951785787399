import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
   
/* muli-regular - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/muli-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/muli-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/muli-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/muli-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/muli-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/muli-v21-latin-regular.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-700 - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/muli-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/muli-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/muli-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/muli-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/muli-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/muli-v21-latin-700.svg#Muli') format('svg'); /* Legacy iOS */
}
/* muli-italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/muli-v21-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/muli-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/muli-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/muli-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/muli-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/muli-v21-latin-italic.svg#Muli') format('svg'); /* Legacy iOS */
}
  
    html,
    body {
      min-height: 100%;
      box-sizing: border-box;


    }
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
    :root {
      -moz-tab-size: 4;
      tab-size: 4;
    }
`

export default GlobalStyle
