import header from "./header"
import homePage from "./homePage"
import footer from "./footer"
import listGuides from "./listGuides"

export default {
  header,
  homePage,
  footer,
  listGuides,
}
